import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import "./footer.scss"

const Footer = () => {
  let intl = useIntl()

  return (
    <footer className="footer">
    </footer>
  )
}

export default Footer
